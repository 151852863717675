import React from 'react';

export default ({
					compare_pages = []
				}) => {

	function isImageFound(component){
		var imagecompoent=component.filter(function(obj){ return  obj.otherbrandImage!=undefined?true:false; });
		if(imagecompoent.length){
			if(imagecompoent[0].otherbrandImage !=undefined){
				return imagecompoent[0].otherbrandImage;
			}else{
				return '';
			}
		}
		return '';
	}
	return (
		<div className="productboxwrapper">
			{compare_pages.map(page => (

				isImageFound(page.components) && (

					<div className="productbox">
						<div className="productimage">
							<a href={'/compare/'+page.slug} aria-label={page.title +'-Image Link'}>
								<picture>
									<source
										srcSet={`${isImageFound(page.components).url}?w=1000&auto=format&q=75`}
										media="(min-width: 400px)"
									/>
									<img
										alt={isImageFound(page.components).alt?isImageFound(page.components).alt:page.title+'-Image'}
										src={`${isImageFound(page.components).url}?w=680&auto=format&q=75`}
									/>
								</picture>
							</a>
						</div>

						<a href={'/compare/'+page.slug} aria-label={page.title}>
							<p className="productname">{page.title}</p>
						</a>
					</div>
				)
			))}

		</div>
	);
};
